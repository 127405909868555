/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFWJ0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFUZ0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFWZ0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVp0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFWp0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFW50bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body { 
	padding-top: 70px; 
	font-family: 'Helvetica Neue', Helvetica, sans-serif;
	overflow-y: scroll;
}
.form-control, .btn {
	border-radius: 0px !important;
}
.model-content {
	border-radius: 0px !important;
}
table a, table a:hover, table a:visited {
	color: black;
	text-decoration: none;
}
h4 {
	font-weight: bold;
}
h3 {
	font-weight: bold;
}
select {
	box-shadow: none;
	background-image: none;
	-webkit-appearance: none;
	border-radius: 0;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
.superscript { 
	vertical-align: super; 
}
.subscript { 
	vertical-align: sub; 
}

[uib-typeahead-popup].dropdown-menu {
	max-width: 95%;
	height: 200px;
	overflow: scroll !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    background-color: #35b5e1 !important;
}

a.btn-danger, a.btn-danger:hover, a.btn-danger:visited {
	color: white;
} 
a.muteLink:hover {
	text-decoration: underline;
	cursor: pointer;
}
a.muteLink p {
	margin: 0;
}
.autoscrolled {
}

#waitspinwheel {
	z-index:100; 
	position: fixed; 
	left:50%; 
	top: 50%; 
	font-size: 30px; 
	text-align:center;  
	transform: translateX(-50%) translateY(-50%); 
	background-color: #111111; 
	color:white; 
	border-radius: 6px; 
	padding: 5px 20px; 
	opacity: 1.0;
	transition: opacity 0.3s;
}
.jumbotron {
	background-color: #e5f1f9;
}

/* Blog */
.blog .title {
	color: #35b5e1;
}
/* Homepage */
.jumbolink, .jumbolink:visited, .jumbolink:hover {
	color: black;
	text-decoration: none;
}
.jumbolink .jumbotron {
	padding: 20px !important;
}

.jumbolink .jumbotron h1 {
	font-size: 25px;
	font-weight: normal;
}
.jumbolink .jumbotron p {
	font-size: 15px;
}
.jumbolink:hover .jumbotron, .jumbolink.active .jumbotron {
	background-color: #35b5e1;
	color: white;
}
/* Login page */
.logo {
	width: 125px;
	margin: 0 auto;
	text-align: center;
}
.login {
	background-image: url('images/login.jpg');
	background-position: right center;
	min-height: 600px;
}
#loginForm .fullwidth {
	width: 100%;
}
#loginForm a, #loginForm a:hover, #loginForm a:visited {
	color: darkgrey;
	text-decoration: underline;
}
.vertical-align {
    display: flex;
    align-items: center;
}
/* Navbar */
nav.navbar {
	background-image: url('images/header.jpg');
	background-position: top center;
}
ul.navbar-nav > li a.menuitem {
	color: #35b5e1;
}
ul.navbar-nav > li a.menuitem:hover {
	background-color: transparent;
	color: #1595c1;
}
ul.navbar-nav > li.active a.menuitem span {
	border-bottom: 2px solid red;
}
ul.navbar-nav > li a.accountitem {
	color: #de5152;
}
ul.navbar-nav > li a.accountitem:hover {
	background-color: white;
	color: #be3132;
}
a.navbar-toggle {
	cursor: pointer;	
}
a.navbar-toggle .icon-bar {
	background-color: #35b5e1;
}
div.navbar-collapse.in.collapse {
	background-color: white;
	border-bottom: thin solid #35b5e1;
}
.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
	background-color: transparent;
}
/* Main menu */
ul.mainmenu li a div {
	width: 20px;
	text-align: center;
	float: left;
}

/* Tabs */
ul.nav-tabs a {
	color: black;
}
.nav-tabs > li, .nav-pills > li {
    float:none;
    display:inline-block;
    *display:inline; /* ie7 fix */
     zoom:1; /* hasLayout ie7 trigger */
}

.nav-tabs, .nav-pills {
    text-align:center;
}
.nav>li>a {
	padding: 6px;
}
.nav-pills>li.active>a, .nav-pills>li.active>a:focus {
	background-color: #25a5d1 !important;
	border-color: #1595c1 !important;
}
.nav-stacked {
	text-align: left !important;
}
.nav-stacked>li+li {
	margin-left: 2px;
}
/* Side navbar */
.sidenavbar {
	padding: 10px;
	background-color: rgba(255,255,255,0.9);
	position: fixed;
	top: 50px;
	left: 0px;
	font-size: 14px;
	line-height: 15px;
	bottom: 0px;
	width: 200px;
	overflow-y: auto;
}
.sidenavbar h3 {
	text-align: center;
	font-weight: normal;
}
sidenavbar ul {
	padding-top: 120px;
}
.sidenavbar li {
	margin-bottom: 2px;
}
.sidenavbar a {
	color: black;
	text-decoration: none;
}
.sidenavbar a span {
	line-height: 22px;
}
.sidenavbar a span.currentComplete {
	font-weight: bold;
	color: #35b5e1;
}
.sidenavbar a span.currentMissing {
	font-weight: bold;
	color: #de5152;
}

.sidenavbar a:hover {
	color: #666666;
	text-decoration: none;
}

.btn-danger:active:hover, .btn-danger.active:hover, .open > .dropdown-toggle.btn-danger:hover, .btn-danger:active:focus, .btn-danger.active:focus, .open > .dropdown-toggle.btn-danger:focus, .btn-danger:active.focus, .btn-danger.active.focus, .open > .dropdown-toggle.btn-danger.focus {
	background-color: #25a5d1;
	border-color: #1595c1;
}
.btn-danger.disabled:hover, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger:hover, .btn-danger.disabled:focus, .btn-danger[disabled]:focus, fieldset[disabled] .btn-danger:focus, .btn-danger.disabled.focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger.focus {
	background-color: #25a5d1;
	border-color: #1595c1;
}
.enterprisebuttons {
	text-align: left;
}
.enterprisebar {
	position: fixed;
	left: 0;
	right: 0;
	top: 51px;
	background: white;
	z-index: 1000;
	padding: 15px 0 15px 0 !important;
}

.enterprisemainview {
	margin-top: 90px;
}

#enterpriseslist {
	margin-top:30px;
}
#enterpriseslist div.enterprise {
	vertical-align: center;
	padding: 6px;
	width: 100%;
	border-bottom: thin solid #efefef;
}
#enterpriseslist div.enterprise i.type {
	padding-right: 6px;
}
#enterpriseslist div.enterprise .notactivated {
	color:#333333;
}
#enterpriseslist div.enterprise .waiting {
	color:#f0ad4e;
}
#enterpriseslist div.enterprise .activated {
	color:#35b5e1;
}
#enterpriseslist div.enterprise .deactivated {
	color:#de5152;
}
#enterpriseslist div.enterprise span.description {
	font-size: 80%;
}

#enterpriseslist div.enterprise:hover {
	background-color: #efefef;
}
#enterpriseslist div.enterprise:hover span.link  {
	cursor: pointer;
}

#breadcrumbs {
	font-size: 20px;
	text-align: left;
	margin-bottom: 20px;
	font-weight: 500;
}

#breadcrumbs .lastcrumb {
	color: black;
}

#breadcrumbs a, #breadcrumbs a:hover, #breadcrumbs a:visited {
	color: #777777;
}

#breadcrumbs a:hover {
	cursor: pointer;
	text-decoration: underline;
}

#folderslist, #fileslist {
	list-style: none;
}

#folderslist div.folder, #fileslist div.file {
	font-size: 120%;
	vertical-align: center;
	padding: 6px;
	width: 100%;
	border-bottom: thin solid #efefef;
}
#folderslist div.folder i, #fileslist div.file i {
	color:#35b5e1;
	padding-right: 6px;
}
#folderslist div.folder:hover, #fileslist div.file:hover {
	background-color: #efefef;
}
#fileslist div.file.selected {
	background-color: #eeeeee !important;
}
#folderslist div.folder:hover span.link, #fileslist div.file:hover span.link  {
	cursor: pointer;
}
#fileslist div.file .commands {
	visibility: hidden;
}

#fileslist div.file:hover .commands {
	visibility: visible;
	cursor: pointer;
}

#fileslist div.footer {
	margin-top:15px;
	margin-bottom:25px;
}
.ellipsis {
	overflow: hidden;
	white-space: none;
	text-overflow: ellipsis;
}

.modal-fullscreen {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}
.modal-fullscreen .modal-content {
	height: auto;
	min-height: 100%;
	border: 0 none;
	border-radius: 0;
	box-shadow: none;
} 

@media (min-width: 768px) {
	.sidebar {
		padding-top: 20px;
		position: fixed;
		top: 50px;
		bottom: 0px;
		overflow-y: auto;
	}
	#loginForm {
		border: thin solid #eeeeee;
		border-radius: 8px;
		box-shadow: 0px 0px 4px 2px #efefef;
		padding: 20px 50px;
	}
	.enterprisebuttons {
		text-align: right;
	}
	.enterprisemainview {
		margin-top: 50px;
	}
	.modal-fullscreen {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}
	.modal-fullscreen .modal-content {
		height: auto;
		min-height: 100%;
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
	}
}

/* Buttons */
.btncustom {
	border: none;
	border-radius: 0;
	margin: 0px 2px !important;
}
.btn-danger[disabled] {
	background-color: #55d5ff;
	border-color: #1595c1;
}

.btn-danger {
	background-color: #35b5e1;
	border-color: #1595c1;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger.focus, .btn-danger:active, .btn-danger.active, .open>.dropdown-toggle.btn-danger {
	background-color: #25a5d1;
	border-color: #1595c1;
}

/* Progress bar */
.progress {
	height: 18px;
}
.progress-bar-success {
	background-color: #35b5e1;
}
.progress-bar-warning {
	background-color: #bfe6f1;
}
/* Textangular & Imgeditor */
.richtexteditor .btn-group {
	margin-bottom: 2px;
}
.ta-btn, .imgeditor-btn {
	border: none;
	border-radius: 0;
	margin: 0px 2px !important;
	cursor: pointer;
}
.ta-btn:hover, .imgeditor-btn:hover {
	transform: scale(1.05);
	background-color: transparent !important;
}
.ta-btn:hover.mirroimage, .imgeditor-btn:hover.mirrorimage {
	transform: scale(-1.05, 1.05);
	background-color: transparent !important;
}
.txteditor-generic, .imgeditor-generic {
	border: 2px solid #bbbbbb !important;
	border-radius: 6px !important;
	padding: 5px !important;
	color: black;
}
.imgeditor-generic {
	background-color: white !important;
}
.imgeditor-generic:hover {
	background-color: white !important;
}
.imgeditor .btn-group {
	margin-left: 15px;
}
.imgeditor-btn {
	margin: 0px 1px !important;
}
/* PSC Chapters */
.ta-bind {
	font-family: Arial, sans-serif;
}
.ta-bind h1 {
	font-size: 150%;
	border-bottom: 2px solid black;
	margin-bottom: 15px;
	page-break-before: always;
	page-break-after: avoid;
}
.ta-bind h2 {
	font-size: 130%;
	border-bottom: 2px solid black;
	margin-bottom: 10px;
	page-break-after: avoid;
}
.ta-bind h3 {
	font-size: 110%;
	margin-bottom: 10px;
	page-break-after: avoid;
}
.ta-bind h4 {
	font-size: 100%;
	margin-bottom: 10px;
	page-break-after: avoid;
}
.ta-bind p {
	text-align: justify;
	margin-bottom: 10px;
}
.ta-bind ol {
	list-style-type: lower-latin;
}
.ta-bind ul {
	list-style-type: initial;
}
.ta-bind li {
	margin-left: 25px;
}
.ta-bind table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0px;
	text-align: justify;
	margin: 10px 0px;
	page-break-inside: auto; 
}	
.ta-bind table thead {
	page-break-inside: avoid; 
	page-break-after: avoid;
}
.ta-bind table tr {
	page-break-inside: avoid; 
	page-break-after: auto;
}
.ta-bind table tr th {
	padding: 6px 6px 6px 6px;
}
.ta-bind table tr td {
	padding: 6px 6px 6px 6px;
}
.ta-bind table.borderless tr td {
	border-bottom: none;
}

.ta-bind table.bottombordered-black tr:last-child td {
	border-bottom: 1px solid black;
}
.ta-bind table.bottombordered-brown tr:last-child td {
	border-bottom: 1px solid #996633;
}
.ta-bind table.bottombordered-red tr:last-child td {
	border-bottom: 1px solid #990000;
}
.ta-bind table.bottombordered-green tr:last-child td {
	border-bottom: 1px solid #538135;
}
.ta-bind table.bottombordered-blue tr:last-child td {
	border-bottom: 1px solid #4574b5;
}

.ta-bind table.bottomborderedcells-black tr td {
	border-bottom: 1px solid black;
}
.ta-bind table.bottomborderedcells-black tr th, .ta-bind table.bottombordered-black tr th {
	color: black;
	border-bottom: 1px solid black;
}
.ta-bind table.bottomborderedcells-brown tr td {
	border-bottom: 1px solid #996633;
}
.ta-bind table.bottomborderedcells-brown tr th, .ta-bind table.bottombordered-brown tr th {
	color: #996633;
	border-bottom: 1px solid #996633;
}

.ta-bind table.bottomborderedcells-red tr td {
	border-bottom: 1px solid #990000;
}
.ta-bind table.bottomborderedcells-red tr th, .ta-bind table.bottombordered-red tr th {
	color: #990000;
	border-bottom: 1px solid #990000;
}
.ta-bind table.bottomborderedcells-green tr td {
	border-bottom: 1px solid #538135;
}
.ta-bind table.bottomborderedcells-green tr th, .ta-bind table.bottombordered-green tr th {
	color: #538135;
	border-bottom: 1px solid #538135;
}
.ta-bind table.bottomborderedcells-blue tr td {
	border-bottom: 1px solid #4574b5;
}
.ta-bind table.bottomborderedcells-blue tr th, .ta-bind table.bottombordered-blue tr th {
	color: #4574b5;
	border-bottom: 1px solid #4574b5;
}

.ta-bind table.fullborderedcells-black tr td {
	border: 1px solid black;
}
.ta-bind table.fullborderedcells-black tr th {
	color: black;
	border: 1px solid black;
}
.ta-bind table.fullborderedcells-brown tr td {
	border: 1px solid #996633;
}
.ta-bind table.fullborderedcells-brown tr th {
	color: black;
	border: 1px solid #996633;
}

.ta-bind table.fullborderedcells-red tr td {
	border: 1px solid #990000;
}
.ta-bind table.fullborderedcells-red tr th {
	color: #990000;
	border: 1px solid #990000;
}
.ta-bind table.fullborderedcells-green tr td {
	border: 1px solid #538135;
}
.ta-bind table.fullborderedcells-green tr th {
	color: #538135;
	border: 1px solid #538135;
}
.ta-bind table.fullborderedcells-blue tr td {
	border: 1px solid #4574b5;
}
.ta-bind table.fullborderedcells-blue tr th {
	color: #4574b5;
	border: 1px solid #4574b5;
}
.ta-bind .text-right {
	text-align: right;
}
.ta-bind .text-left {
	text-align: left;
}
.ta-bind .text-center {
	text-align: center;
}
.ta-bind .text-middle {
	vertical-align:middle;
}
.ta-bind .indented {
	margin-left: 15px;
	text-indent: -15px;
}
.ta-bind ul.lowerlatin {
	list-style-type: lower-latin;
}

.ta-bind ul.decimal {
	list-style-type: decimal;
}

.ta-bind .underline {
	text-decoration: underline;
}

.ta-bind .bordered-black {
	padding: 5px; 
	border: thin solid black;
}
.ta-bind .bordered-brown {
	padding: 5px; 
	border: thin solid #996633;
	page-break-inside: avoid;
	margin: 10px 0 10px 0;
}

.ta-bind .bordered-red {
	padding: 5px; 
	border: thin solid #990000;
}
.ta-bind .bordered-green {
	padding: 5px; 
	border: thin solid #538135;
}
.ta-bind .bordered-blue {
	padding: 5px; 
	border: thin solid #4574b5;
}
.ta-bind .background-gray {
	background-color: #dddddd;
}
.ta-bind .black-text {
	color: black;
}
.ta-bind .brown-text {
	color: #996633;
}
.ta-bind .red-text {
	color: #990000;
}
.ta-bind .green-text {
	color: #538135;
}
.ta-bind .blue-text {
	color: #4574b5;
}
.ta-bind table tr th.width4 {
	width: 4%;
}
.ta-bind table tr th.width10 {
	width: 10%;
}
.ta-bind table tr th.width15 {
	width: 15%;
}
.ta-bind table tr th.width20 {
	width: 20%;
}
.ta-bind table tr th.width25f {
	width: 24.5%;
}
.ta-bind table tr th.width25 {
	width: 25%;
}
.ta-bind table tr th.width30 {
	width: 30%;
}
.ta-bind table tr th.width40 {
	width: 40%;
}
.ta-bind table tr th.width48 {
	width: 48%;
}
.ta-bind table tr th.width50 {
	width: 50%;
}
.ta-bind table tr th.width60 {
	width: 60%;
}
.ta-bind table tr th.width70 {
	width: 70%;
}
.ta-bind .avoidpagebreakinside {
	page-break-inside: avoid;
}
.ta-bind .forcepagebreakbefore {
	page-break-before: always;
}
.ta-bind .avoidpagebreakbefore {
	page-break-before: avoid;
}
.ta-bind .no-marginbottom {
	margin-bottom: 0px !important;
}
.ta-bind .arrow {
	font-size: 30px;
}
/* POSS */
.possrow, .pscsrow {
	margin-top: 10px;
	margin-bottom: 10px;
}
.posslink, .pscslink, .tablelink {
	color: black;
	cursor: pointer;
}
.tablelink div {
	margin-top: 5px;
	margin-bottom: 5px;
}
.selectedplan td {
	background-color: #d9edf7;
}
.posslink:hover, .pscslink:hover, .tablelink:hover {
	color: black;
}
.posslink:hover div, .pscslink:hover div, .tablelink:hover div {
	background-color: #e5f1f9;
}
/* General context navbar */
.contextnavbar {
	border-bottom: thin solid lightgray;
	padding: 0 15px 0px;
}
/* Main navbar */
.mainbar {
	min-height: 95px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: rgba(255,255,255,0.9);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
	position: fixed;
	top: 50px;
	left: 0px;
	right: 0px;
	z-index: 1025;
}

@media (min-width: 992px) {
	.mainbar {
		left:200px;
	}
	.modal-fullscreen {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}
	.modal-fullscreen .modal-content {
		height: auto;
		min-height: 100%;
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
	}
	
}
.documents {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	position: fixed;
	right: 0px;
	top: -400px;
	left: 0px;
	background-color: white;
	border: thin solid gray;
	max-height: 400px;
	overflow-y: auto;
	background: linear-gradient(white, #f5f5f5); /* Standard syntax */
	z-index: 1000;
}
.documents p {
	padding: 10px;
}
.documents.show {
	top: 140px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.documents .loading {
	margin: 30px auto;
	text-align: center;
}
@media (min-width: 992px) {
	
	.documents {
		left:200px;
	}
	.documents.show {
		top: 155px;
	}
}

/* Horizontal list tile */
.objectswell {
	background-color: #ffffff;
	padding: 10px;
	border: thin solid lightgray;
}

.list-inline .tile {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	border: 2px solid #bbbbbb;
	text-align: center;
	overflow-x: auto;
	padding: 3px;
	border-radius: 8px;
	background-color: white;
}
.list-inline .tile div {
    min-height: 60px;
    min-width: 60px;
}

.list-inline .tile:hover {
	border: 2px solid #666666;
}

.list-inline .tile i {
	min-width: 60px;
	min-height: 60px;
	font-size:60px;
	color: #999999;
}
.list-inline .tile.active {
	border: 2px solid #ff0000;
}
/* TextAngular code */
.ta-editor {
    min-height: 300px;
    height: auto;
    overflow: auto;
    font-family: inherit;
    font-size: 100%;
    margin:20px 0;
}
.ta-scroll-window {
	max-height: 600px;
	overflow-y: auto;
}
div[contenteditable="true"]:focus {
	outline: none;
}
/* End TextAngular code */

/* Drag and drop compatibility */
[draggable=true] {
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

#droparea {
	width: 100%; 
	background-color: #eeeeee; 
	border: thin solid #cccccc; 
	border-radius: 5px; 
	margin: 0 auto; 
	text-align: center; 
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
#droparea div {
	margin: 0 auto;
	background-color: rgba(80,80,80,0.0);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 0.0;
}
#droparea div:hover  {
	background-color: rgba(80,80,80,0.6);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 1.0;
}

#droparea.dragover {
	background-color: #fbffc4; 
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	cursor: copy;
}
#droparea.nodrop {
	cursor: no-drop;
}
/* End Drag and drop compatibility */

canvas:focus {
	outline: none;
}
.nav-pills>li>a {
	color: #333333;
}
.nav-pills>li.active>a, .nav-pills>li.active>a:focus {
	color: #ffffff;
	background-color: #d9534f;
}
.nav-pills>li.active>a:hover {
	color: #ffffff;
	background-color: #d2322d;
}
.custom .form-row-divider {
	margin-bottom: 10px;
}

.custom data-ng-form {
	margin-top: 0em;
	display: block;
}
/* Container */
.custom .container {
	margin-bottom: 30px;
}
.custom input, .custom textarea {
	background-color: #f9f9f9;
	color: #252525;
}
.custom .error-label {
	color: #d9534f;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 500;
}

.custom .control-label {
	color: #999999;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 500;
}
.custom .form-control {
	background-color: #f9f9f9;
}
.custom .panel-group .panel {
	overflow-y: inherit;
}
.custom .top-buffer {
	margin-bottom: 20px;
	min-height: 20px;
}
/* Navbar */
.topbar .navbar {
	background: linear-gradient(white, #f5f5f5); /* Standard syntax */
	-webkit-box-shadow: 0px 0px 6px 2px #cccccc;
	box-shadow: 0px 0px 6px 2px #cccccc;
}
.topbar .nav>li:first-child {
	border-left: thin solid lightgray;
}

.topbar .nav>li {
	border-right: thin solid lightgray;
}

.topbar .element {
	background-color: #f5f5f5;
	margin-top:10px;
}

.topbar .nav.nav-stacked>li{
	border: none;
}

/* Fieldset */
.custom legend {
	font-weight: 500;
	font-height: 16px;
	border: none;
}

/* Accordion */
.custom .panel {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.custom .panel-default {
	border: none;
}

.custom .panel-default > .panel-heading {
	text-transform: uppercase;
	border-bottom: thin solid lightgray;
	background: none;
}
.custom .panel-default>.panel-heading+.panel-collapse .panel-body {
	border: none;
}
.custom .open-panel .panel-heading h4 {
	color: #666666 !important;
}

.custom .open-panel .panel-heading h4:after {
	content:'-';
	float:right;
}

.custom .closed-panel .panel-heading h4:after {
	content:'+';
	float:right;
}
.custom .list .panel-default > .panel-heading {
	text-transform: none;
	border-bottom: none;
}
.custom .list .panel-heading h4 {
	font-weight: normal;
	font-size: 14px;
}
/* Colorwell */
.custom .colorwell {
	width: 24px;
	height: 24px;
	border-radius: 12px;
}

/* Date picker */
.custom div.calendar table td button {
	border: none !important;
}
/* Categories table */
.custom .categories th	, .custom .categories td {
	border: none;
}
.custom .categories tr td:first-child {
	text-transform: uppercase;
}
.custom .categories a, .categories a:visited {
	color: #e4e4e4;
}
.no-gutter {
    padding-left:0;
    padding-right:0;
}
.red {
	background-color: red;
}

.black {
	background-color: black;
}

.selectableimage:hover {
	transform: scale(1.1,1.1);
	-ms-transform: scale(1.1,1.1);
	-moz-transform: scale(1.1,1.1);
	-webkit-transform: scale(1.1,1.1);
	cursor: pointer;
}



/* Generated by http://responsive-css.spritegen.com Responsive CSS Sprite Generator */
.chapter1-start, .chapter11-informazione-e-formazione, .chapter12-emergenze, .chapter13-lavori, .chapter14-segnaletica, 
.chapter15-firme, .chapter16-allegati, .chapter17-modulistica, .chapter18-dati-cantiere, .chapter19-valutazione-dei-rischi, 
.chapter2-dati-impresa, .chapter20-coordinamento, .chapter21-fascicolo-dellopera, .chapter22-stima-dei-costi, .chapter3-sicurezza, 
.chapter4-contesto, .chapter5-opere-e-mezzi-di-cantiere, .chapter6-sostanze-pericolose, .chapter7-rischi-fisici, .chapter8-individuazione-delle-misure, 
.chapter9-procedure-complementari-al-psc, .chapter10-elenco-dpi
{ max-width: 100%; background-size: 100%; background-image: url('images/images.jpg'); }
 
.chapter1-start { background-position: 0 0%; background-size: 102.564103%; }
.chapter11-informazione-e-formazione { background-position: 0 4.744099%; background-size: 102.564103%; }
.chapter12-emergenze { background-position: 0 9.488198%; background-size: 102.564103%; }
.chapter13-lavori { background-position: 0 14.232297%; background-size: 102.564103%; }
.chapter14-segnaletica { background-position: 0 18.979353%; background-size: 102.783726%; }
.chapter15-firme { background-position: 0 23.845135%; background-size: 102.564103%; }
.chapter16-allegati { background-position: 0 28.589234%; background-size: 102.564103%; }
.chapter17-modulistica { background-position: 0 33.333333%; background-size: 102.564103%; }
.chapter18-dati-cantiere { background-position: 0 38.077432%; background-size: 102.564103%; }
.chapter19-valutazione-dei-rischi { background-position: 0 42.821532%; background-size: 102.564103%; }
.chapter2-dati-impresa { background-position: 0 47.547111%; background-size: 103.225806%; }
.chapter20-coordinamento { background-position: 0 52.30973%; background-size: 102.564103%; }
.chapter21-fascicolo-dellopera { background-position: 0 57.053829%; background-size: 102.564103%; }
.chapter22-stima-dei-costi { background-position: 0 61.797928%; background-size: 102.564103%; }
.chapter3-sicurezza { background-position: 0 66.516119%; background-size: 103.004292%; }
.chapter4-contesto { background-position: 0 71.319461%; background-size: 102.564103%; }
.chapter5-opere-e-mezzi-di-cantiere { background-position: 0 76.107435%; background-size: 102.564103%; }
.chapter6-sostanze-pericolose { background-position: 0 80.898964%; background-size: 102.564103%; }
.chapter7-rischi-fisici { background-position: 0 85.643063%; background-size: 102.564103%; }
.chapter8-individuazione-delle-misure { background-position: 0 90.387162%; background-size: 102.564103%; }
.chapter9-procedure-complementari-al-psc { background-position: 0 95.175746%; background-size: 102.564103%; }
.chapter10-elenco-dpi { background-position: 0 100%; background-size: 103.896104%; }

/* Generated by http://css.spritegen.com CSS Sprite Generator */
 
.num-10blu.active, .num-10rosso.active, .num-11blu.active, .num-11rosso.active, .num-12blu.active, 
.num-12rosso.active, .num-13blu.active, .num-13rosso.active, .num-14blu.active, .num-14rosso.active, 
.num-15blu.active, .num-15rosso.active, .num-16blu.active, .num-16rosso.active, .num-17blu.active, 
.num-17rosso.active, .num-18blu.active, .num-18rosso.active, .num-19blu.active, .num-19rosso.active, 
.num-1blu.active, .num-1rosso.active, .num-20blu.active, .num-20rosso.active, .num-21blu.active, 
.num-21rosso.active, .num-22blu.active, .num-22rosso.active, .num-2blu.active, .num-2rosso.active, 
.num-3blu.active, .num-3rosso.active, .num-4blu.active, .num-4rosso.active, .num-5blu.active, 
.num-5rosso.active, .num-6blu.active, .num-6rosso.active, .num-7blu.active, .num-7rosso.active, 
.num-8blu.active, .num-8rosso.active, .num-9blu.active, .num-9rosso.active, .num-10blu, 
.num-10rosso, .num-11blu, .num-11rosso, .num-12blu, .num-12rosso, 
.num-13blu, .num-13rosso, .num-14blu, .num-14rosso, .num-15blu, 
.num-15rosso, .num-16blu, .num-16rosso, .num-17blu, .num-17rosso, 
.num-18blu, .num-18rosso, .num-19blu, .num-19rosso, .num-1blu, 
.num-1rosso, .num-20blu, .num-20rosso, .num-21blu, .num-21rosso, 
.num-22blu, .num-22rosso, .num-2blu, .num-2rosso, .num-3blu, 
.num-3rosso, .num-4blu, .num-4rosso, .num-5blu, .num-5rosso, 
.num-6blu, .num-6rosso, .num-7blu, .num-7rosso, .num-8blu, 
.num-8rosso, .num-9blu, .num-9rosso, .imgeditor-colore-forma, .imgeditor-colore-forma.active, 
.imgeditor-colore-testo, .imgeditor-colore-testo.active, .imgeditor-dimensione-testo, .imgeditor-dimensione-testo.active, .txteditor-colore-testo, 
.txteditor-colore-testo.active, .txteditor-dimensione-testo, .txteditor-dimensione-testo.active, .btn-aggiungi, .btn-aggiungi.active, 
.btn-carica, .btn-carica.active, .btn-cestino, .btn-cestino.active, .btn-copia, 
.btn-copia.active, .btn-fai-la-domanda, .btn-guarda-video, .btn-leggi-istruzioni, .btn-modifica, 
.btn-modifica.active, .btn-ricarica, .btn-ricarica.active, .btn-stampa, .btn-stampa.active, 
.imgeditor-casella-testo, .imgeditor-casella-testo.active, .imgeditor-cerca, .imgeditor-cerca.active, .imgeditor-cestina, 
.imgeditor-cestina.active, .imgeditor-copia-grigio, .imgeditor-copia-grigio.active, .imgeditor-ellisse, .imgeditor-ellisse.active, 
.imgeditor-forma, .imgeditor-forma.active, .imgeditor-linea, .imgeditor-linea.active, .imgeditor-primo-piano, 
.imgeditor-primo-piano.active, .imgeditor-puntatore, .imgeditor-puntatore.active, .imgeditor-rettangolo, .imgeditor-rettangolo.active, 
.imgeditor-ritaglia, .imgeditor-ritaglia.active, .imgeditor-ruota, .imgeditor-ruota.active, .imgeditor-secondo-piano, 
.imgeditor-secondo-piano.active, .imgeditor-simbolo, .imgeditor-simbolo.active, .imgeditor-sposta, .imgeditor-sposta.active, 
.txteditor-corsivo, .txteditor-corsivo.active, .txteditor-elenco-numerato, .txteditor-elenco-numerato.active, .txteditor-elenco-puntato, 
.txteditor-elenco-puntato.active, .txteditor-grassetto, .txteditor-grassetto.active, .txteditor-immagine, .txteditor-immagine.active, 
.txteditor-paragrafo-centro, .txteditor-paragrafo-centro.active, .txteditor-paragrafo-giustificato, .txteditor-paragrafo-giustificato.active, .txteditor-paragrafo, 
.txteditor-paragrafo.active, .txteditor-paragrafodx, .txteditor-paragrafodx.active, .txteditor-paragrafosx, .txteditor-paragrafosx.active, 
.txteditor-rientro-margine-1, .txteditor-rientro-margine-1.active, .txteditor-rientro-margine-2, .txteditor-rientro-margine-2.active, .txteditor-ritaglia, 
.txteditor-ritaglia.active, .txteditor-sottolineato, .txteditor-sottolineato.active, .txteditor-testo-piu-grande, .txteditor-testo-piu-grande.active, 
.txteditor-testo-piu-piccolo, .txteditor-testo-piu-piccolo.active, .btn-trascina-immagine-qui, .mark-capitolo-completato, .menu-cantiere, 
.menu-testi-pos, .menu-imprese-edili, .menu-rischi, .menu-manutenzioni, .menu-cartelli, 
.menu-indicazioni, .menu-lavorazioni, .menu-procedure, .menu-simboli, .menu-sostanze-pericolose, 
.menu-dpi, .menu-anagrafica, .menu-rifiuti, .mark-capitolo-mancante
{ 	display: inline-block; 
	background-color: transparent;
	background-image: url('images/icons.png') !important; 
	background-repeat: no-repeat;
	overflow: hidden; 
	text-indent: -9999px; 
	text-align: left; 
}
 
.num-10blu.active { background-position: -1px -0px; width: 45px; height: 45px; }
.num-10rosso.active { background-position: -1px -46px; width: 45px; height: 45px; }
.num-11blu.active { background-position: -1px -92px; width: 45px; height: 45px; }
.num-11rosso.active { background-position: -1px -138px; width: 45px; height: 45px; }
.num-12blu.active { background-position: -1px -184px; width: 45px; height: 45px; }
.num-12rosso.active { background-position: -1px -230px; width: 45px; height: 45px; }
.num-13blu.active { background-position: -1px -276px; width: 45px; height: 45px; }
.num-13rosso.active { background-position: -1px -322px; width: 45px; height: 45px; }
.num-14blu.active { background-position: -1px -368px; width: 45px; height: 45px; }
.num-14rosso.active { background-position: -1px -414px; width: 45px; height: 45px; }
.num-15blu.active { background-position: -1px -460px; width: 45px; height: 45px; }
.num-15rosso.active { background-position: -1px -506px; width: 45px; height: 45px; }
.num-16blu.active { background-position: -1px -552px; width: 45px; height: 45px; }
.num-16rosso.active { background-position: -1px -598px; width: 45px; height: 45px; }
.num-17blu.active { background-position: -1px -644px; width: 45px; height: 45px; }
.num-17rosso.active { background-position: -1px -690px; width: 45px; height: 45px; }
.num-18blu.active { background-position: -1px -736px; width: 45px; height: 45px; }
.num-18rosso.active { background-position: -1px -782px; width: 45px; height: 45px; }
.num-19blu.active { background-position: -1px -828px; width: 45px; height: 45px; }
.num-19rosso.active { background-position: -1px -874px; width: 45px; height: 45px; }
.num-1blu.active { background-position: -1px -920px; width: 45px; height: 45px; }
.num-1rosso.active { background-position: -1px -966px; width: 45px; height: 45px; }
.num-20blu.active { background-position: -1px -1012px; width: 45px; height: 45px; }
.num-20rosso.active { background-position: -1px -1058px; width: 45px; height: 45px; }
.num-21blu.active { background-position: -1px -1104px; width: 45px; height: 45px; }
.num-21rosso.active { background-position: -1px -1150px; width: 45px; height: 45px; }
.num-22blu.active { background-position: -1px -1196px; width: 45px; height: 45px; }
.num-22rosso.active { background-position: -1px -1242px; width: 45px; height: 45px; }
.num-2blu.active { background-position: -1px -1288px; width: 45px; height: 45px; }
.num-2rosso.active { background-position: -1px -1334px; width: 45px; height: 45px; }
.num-3blu.active { background-position: -1px -1380px; width: 45px; height: 45px; }
.num-3rosso.active { background-position: -1px -1426px; width: 45px; height: 45px; }
.num-4blu.active { background-position: -1px -1472px; width: 45px; height: 45px; }
.num-4rosso.active { background-position: -1px -1518px; width: 45px; height: 45px; }
.num-5blu.active { background-position: -1px -1564px; width: 45px; height: 45px; }
.num-5rosso.active { background-position: -1px -1610px; width: 45px; height: 45px; }
.num-6blu.active { background-position: -1px -1656px; width: 45px; height: 45px; }
.num-6rosso.active { background-position: -1px -1702px; width: 45px; height: 45px; }
.num-7blu.active { background-position: -1px -1748px; width: 45px; height: 45px; }
.num-7rosso.active { background-position: -1px -1794px; width: 45px; height: 45px; }
.num-8blu.active { background-position: -1px -1840px; width: 45px; height: 45px; }
.num-8rosso.active { background-position: -1px -1886px; width: 45px; height: 45px; }
.num-9blu.active { background-position: -1px -1932px; width: 45px; height: 45px; }
.num-9rosso.active { background-position: -1px -1978px; width: 45px; height: 45px; }
.num-10blu { background-position: -1px -2024px; width: 43px; height: 43px; }
.num-10rosso { background-position: -1px -2068px; width: 43px; height: 43px; }
.num-11blu { background-position: -1px -2112px; width: 43px; height: 43px; }
.num-11rosso { background-position: -1px -2156px; width: 43px; height: 43px; }
.num-12blu { background-position: -1px -2200px; width: 43px; height: 43px; }
.num-12rosso { background-position: -1px -2244px; width: 43px; height: 43px; }
.num-13blu { background-position: -1px -2288px; width: 43px; height: 43px; }
.num-13rosso { background-position: -1px -2332px; width: 43px; height: 43px; }
.num-14blu { background-position: -1px -2376px; width: 43px; height: 43px; }
.num-14rosso { background-position: -1px -2420px; width: 43px; height: 43px; }
.num-15blu { background-position: -1px -2464px; width: 43px; height: 43px; }
.num-15rosso { background-position: -1px -2508px; width: 43px; height: 43px; }
.num-16blu { background-position: -1px -2552px; width: 43px; height: 43px; }
.num-16rosso { background-position: -1px -2596px; width: 43px; height: 43px; }
.num-17blu { background-position: -1px -2640px; width: 43px; height: 43px; }
.num-17rosso { background-position: -1px -2684px; width: 43px; height: 43px; }
.num-18blu { background-position: -1px -2728px; width: 43px; height: 43px; }
.num-18rosso { background-position: -1px -2772px; width: 43px; height: 43px; }
.num-19blu { background-position: -1px -2816px; width: 43px; height: 43px; }
.num-19rosso { background-position: -1px -2860px; width: 43px; height: 43px; }
.num-1blu { background-position: -1px -2904px; width: 43px; height: 43px; }
.num-1rosso { background-position: -1px -2948px; width: 43px; height: 43px; }
.num-20blu { background-position: -1px -2992px; width: 43px; height: 43px; }
.num-20rosso { background-position: -1px -3036px; width: 43px; height: 43px; }
.num-21blu { background-position: -1px -3080px; width: 43px; height: 43px; }
.num-21rosso { background-position: -1px -3124px; width: 43px; height: 43px; }
.num-22blu { background-position: -1px -3168px; width: 43px; height: 43px; }
.num-22rosso { background-position: -1px -3212px; width: 43px; height: 43px; }
.num-2blu { background-position: -1px -3256px; width: 43px; height: 43px; }
.num-2rosso { background-position: -1px -3300px; width: 43px; height: 43px; }
.num-3blu { background-position: -1px -3344px; width: 43px; height: 43px; }
.num-3rosso { background-position: -1px -3388px; width: 43px; height: 43px; }
.num-4blu { background-position: -1px -3432px; width: 43px; height: 43px; }
.num-4rosso { background-position: -1px -3476px; width: 43px; height: 43px; }
.num-5blu { background-position: -1px -3520px; width: 43px; height: 43px; }
.num-5rosso { background-position: -1px -3564px; width: 43px; height: 43px; }
.num-6blu { background-position: -1px -3608px; width: 43px; height: 43px; }
.num-6rosso { background-position: -1px -3652px; width: 43px; height: 43px; }
.num-7blu { background-position: -1px -3696px; width: 43px; height: 43px; }
.num-7rosso { background-position: -1px -3740px; width: 43px; height: 43px; }
.num-8blu { background-position: -1px -3784px; width: 43px; height: 43px; }
.num-8rosso { background-position: -1px -3828px; width: 43px; height: 43px; }
.num-9blu { background-position: -1px -3872px; width: 43px; height: 43px; }
.num-9rosso { background-position: -1px -3916px; width: 43px; height: 43px; }
.imgeditor-colore-forma { background-position: -1px -3960px; width: 41px; height: 34px; }
.imgeditor-colore-forma.active { background-position: -1px -3995px; width: 41px; height: 34px; }
.imgeditor-colore-testo { background-position: -1px -4030px; width: 41px; height: 34px; }
.imgeditor-colore-testo.active { background-position: -1px -4065px; width: 41px; height: 34px; }
.imgeditor-dimensione-testo { background-position: -1px -4100px; width: 41px; height: 34px; }
.imgeditor-dimensione-testo.active { background-position: -1px -4135px; width: 41px; height: 34px; }
.txteditor-colore-testo { background-position: -1px -4170px; width: 41px; height: 34px; }
.txteditor-colore-testo.active { background-position: -1px -4205px; width: 41px; height: 34px; }
.txteditor-dimensione-testo { background-position: -1px -4240px; width: 41px; height: 34px; }
.txteditor-dimensione-testo.active { background-position: -1px -4275px; width: 41px; height: 34px; }
.btn-aggiungi { background-position: -1px -4310px; width: 34px; height: 34px; }
.btn-aggiungi.active { background-position: -1px -4345px; width: 34px; height: 34px; }
.btn-carica { background-position: -1px -4380px; width: 34px; height: 34px; }
.btn-carica.active { background-position: -1px -4415px; width: 34px; height: 34px; }
.btn-cestino { background-position: -1px -4450px; width: 34px; height: 34px; }
.btn-cestino.active { background-position: -1px -4485px; width: 34px; height: 34px; }
.btn-copia { background-position: -1px -4520px; width: 34px; height: 34px; }
.btn-copia.active { background-position: -1px -4555px; width: 34px; height: 34px; }
.btn-fai-la-domanda { background-position: -1px -4590px; width: 34px; height: 34px; }
.btn-guarda-video { background-position: -1px -4625px; width: 34px; height: 34px; }
.btn-leggi-istruzioni { background-position: -1px -4660px; width: 34px; height: 34px; }
.btn-modifica { background-position: -1px -4695px; width: 34px; height: 34px; }
.btn-modifica.active { background-position: -1px -4730px; width: 34px; height: 34px; }
.btn-ricarica { background-position: -1px -4765px; width: 34px; height: 34px; }
.btn-ricarica.active { background-position: -1px -4800px; width: 34px; height: 34px; }
.btn-stampa { background-position: -1px -4835px; width: 34px; height: 34px; }
.btn-stampa.active { background-position: -1px -4870px; width: 34px; height: 34px; }
.imgeditor-casella-testo { background-position: -1px -4905px; width: 34px; height: 34px; }
.imgeditor-casella-testo.active { background-position: -1px -4940px; width: 34px; height: 34px; }
.imgeditor-cerca { background-position: -1px -4975px; width: 34px; height: 34px; }
.imgeditor-cerca.active { background-position: -1px -5010px; width: 34px; height: 34px; }
.imgeditor-cestina { background-position: -1px -5045px; width: 34px; height: 34px; }
.imgeditor-cestina.active { background-position: -1px -5080px; width: 34px; height: 34px; }
.imgeditor-copia-grigio { background-position: -1px -5115px; width: 34px; height: 34px; }
.imgeditor-copia-grigio.active { background-position: -1px -5150px; width: 34px; height: 34px; }
.imgeditor-ellisse { background-position: -1px -5185px; width: 34px; height: 34px; }
.imgeditor-ellisse.active { background-position: -1px -5220px; width: 34px; height: 34px; }
.imgeditor-forma { background-position: -1px -5255px; width: 34px; height: 34px; }
.imgeditor-forma.active { background-position: -1px -5290px; width: 34px; height: 34px; }
.imgeditor-linea { background-position: -1px -5325px; width: 34px; height: 34px; }
.imgeditor-linea.active { background-position: -1px -5360px; width: 34px; height: 34px; }
.imgeditor-primo-piano { background-position: -1px -5395px; width: 34px; height: 34px; }
.imgeditor-primo-piano.active { background-position: -1px -5430px; width: 34px; height: 34px; }
.imgeditor-puntatore { background-position: -1px -5465px; width: 34px; height: 34px; }
.imgeditor-puntatore.active { background-position: -1px -5500px; width: 34px; height: 34px; }
.imgeditor-rettangolo { background-position: -1px -5535px; width: 34px; height: 34px; }
.imgeditor-rettangolo.active { background-position: -1px -5570px; width: 34px; height: 34px; }
.imgeditor-ritaglia { background-position: -1px -5605px; width: 34px; height: 34px; }
.imgeditor-ritaglia.active { background-position: -1px -5640px; width: 34px; height: 34px; }
.imgeditor-ruota { background-position: -1px -5675px; width: 34px; height: 34px; }
.imgeditor-ruota.active { background-position: -1px -5710px; width: 34px; height: 34px; }
.imgeditor-secondo-piano { background-position: -1px -5745px; width: 34px; height: 34px; }
.imgeditor-secondo-piano.active { background-position: -1px -5780px; width: 34px; height: 34px; }
.imgeditor-simbolo { background-position: -1px -5815px; width: 34px; height: 34px; }
.imgeditor-simbolo.active { background-position: -1px -5850px; width: 34px; height: 34px; }
.imgeditor-sposta { background-position: -1px -5885px; width: 34px; height: 34px; }
.imgeditor-sposta.active { background-position: -1px -5920px; width: 34px; height: 34px; }
.txteditor-corsivo { background-position: -1px -5955px; width: 34px; height: 34px; }
.txteditor-corsivo.active { background-position: -1px -5990px; width: 34px; height: 34px; }
.txteditor-elenco-numerato { background-position: -1px -6025px; width: 34px; height: 34px; }
.txteditor-elenco-numerato.active { background-position: -1px -6060px; width: 34px; height: 34px; }
.txteditor-elenco-puntato { background-position: -1px -6095px; width: 34px; height: 34px; }
.txteditor-elenco-puntato.active { background-position: -1px -6130px; width: 34px; height: 34px; }
.txteditor-grassetto { background-position: -1px -6165px; width: 34px; height: 34px; }
.txteditor-grassetto.active { background-position: -1px -6200px; width: 34px; height: 34px; }
.txteditor-immagine { background-position: -1px -6235px; width: 34px; height: 34px; }
.txteditor-immagine.active { background-position: -1px -6270px; width: 34px; height: 34px; }
.txteditor-paragrafo-centro { background-position: -1px -6305px; width: 34px; height: 34px; }
.txteditor-paragrafo-centro.active { background-position: -1px -6340px; width: 34px; height: 34px; }
.txteditor-paragrafo-giustificato { background-position: -1px -6375px; width: 34px; height: 34px; }
.txteditor-paragrafo-giustificato.active { background-position: -1px -6410px; width: 34px; height: 34px; }
.txteditor-paragrafo { background-position: -1px -6445px; width: 34px; height: 34px; }
.txteditor-paragrafo.active { background-position: -1px -6480px; width: 34px; height: 34px; }
.txteditor-paragrafodx { background-position: -1px -6515px; width: 34px; height: 34px; }
.txteditor-paragrafodx.active { background-position: -1px -6550px; width: 34px; height: 34px; }
.txteditor-paragrafosx { background-position: -1px -6585px; width: 34px; height: 34px; }
.txteditor-paragrafosx.active { background-position: -1px -6620px; width: 34px; height: 34px; }
.txteditor-rientro-margine-1 { background-position: -1px -6655px; width: 34px; height: 34px; }
.txteditor-rientro-margine-1.active { background-position: -1px -6690px; width: 34px; height: 34px; }
.txteditor-rientro-margine-2 { background-position: -1px -6725px; width: 34px; height: 34px; }
.txteditor-rientro-margine-2.active { background-position: -1px -6760px; width: 34px; height: 34px; }
.txteditor-ritaglia { background-position: -1px -6795px; width: 34px; height: 34px; }
.txteditor-ritaglia.active { background-position: -1px -6830px; width: 34px; height: 34px; }
.txteditor-sottolineato { background-position: -1px -6865px; width: 34px; height: 34px; }
.txteditor-sottolineato.active { background-position: -1px -6900px; width: 34px; height: 34px; }
.txteditor-testo-piu-grande { background-position: -1px -6935px; width: 34px; height: 34px; }
.txteditor-testo-piu-grande.active { background-position: -1px -6970px; width: 34px; height: 34px; }
.txteditor-testo-piu-piccolo { background-position: -1px -7005px; width: 34px; height: 34px; }
.txteditor-testo-piu-piccolo.active { background-position: -1px -7040px; width: 34px; height: 34px; }
.btn-trascina-immagine-qui { background-position: -1px -7075px; width: 33px; height: 34px; }
.mark-capitolo-completato { background-position: -1px -7110px; width: 17px; height: 18px; }
.menu-cantiere { background-position: -19px -7110px; width: 15px; height: 12px; }
.menu-testi-pos { background-position: -19px -7123px; width: 15px; height: 12px; }
.menu-imprese-edili { background-position: -1px -7129px; width: 14px; height: 12px; }
.menu-rischi { background-position: -16px -7136px; width: 14px; height: 12px; }
.menu-manutenzioni { background-position: -31px -7136px; width: 13px; height: 12px; }
.menu-cartelli { background-position: -1px -7142px; width: 12px; height: 12px; }
.menu-indicazioni { background-position: -14px -7149px; width: 12px; height: 12px; }
.menu-lavorazioni { background-position: -27px -7149px; width: 12px; height: 12px; }
.menu-procedure { background-position: -1px -7155px; width: 12px; height: 12px; }
.menu-simboli { background-position: -14px -7162px; width: 12px; height: 12px; }
.menu-sostanze-pericolose { background-position: -27px -7162px; width: 11px; height: 12px; }
.menu-dpi { background-position: -35px -7075px; width: 10px; height: 12px; }
.menu-anagrafica { background-position: -35px -7088px; width: 10px; height: 12px; }
.menu-rifiuti { background-position: -36px -4310px; width: 9px; height: 12px; }
.mark-capitolo-mancante { background-position: -36px -4323px; width: 4px; height: 18px; }


.enterprise-document-droparea {
	background-color: #ffffff; 
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.enterprise-document-droparea.dragover {
	background-color: #fbffc4; 
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	cursor: copy;
}
.enterprise-document-droparea.nodrop {
	cursor: no-drop;
}
